import { setAppBadge, clearAppBadge } from '../mixins/appBadge'
import Vue from 'vue'

export default {
    ADD_CONNECTED_MODULES(state, value) {
        state.connectedModules.push(value)
    },
    SET_SHOW_LANG_MESSAGE(state, value) {
        state.showLangMessage = value
    },
    SET_BROWSER_LANG(state, value) {
        state.browserLang = value
    },
    ADD_CONNECTED_ROOMS(state, value) {
        state.connectedRooms.push(value)
    },
    DELETE_CONNECTED_ROOMS(state, value) {
        const index = state.connectedRooms.findIndex(f => f === value)
        if(index !== -1) {
            Vue.delete(state.connectedRooms, index)
        }
    },
    TOGGLE_ASIDE_TYPE(state, value) {
        state.asideType = value
        localStorage.setItem('asideType', JSON.stringify(value))
    },
    SET_CACHE_UID(state, value) {
        state.cacheUID = value
    },
    TOGGLE_ONLINE(state, value) {
        state.online = value
    },
    TOGGLE_DOCUMENT_REVERSE(state, value) {
        localStorage.setItem('documentReverse', JSON.stringify(value))
        state.documentReverse = value
    },
    TOGGLE_EYE_VERSION(state, value) {
        localStorage.setItem('eyeVersionStorage', JSON.stringify(value))
        state.eyeVersion = value
    },
    TOGGLE_WRAPPER_PADDING(state, value) {
        state.wrapperPadding = value
    },
    UPDATE_WINDOW_WIDTH(state, value) {
        state.windowWidth = value
    },
    UPDATE_WINDOW_HEIGHT(state, value) {
        state.windowHeight = value
    },
    ADD_LOGIN_PROGRESS(state, value) {
        state.loginProgress = state.loginProgress + value
    },
    SET_SW_UPDATE(state, value) {
        state.swUpdate = value
    },
    SET_SW_REG(state, value) {
        state.swRegistration = value
    },
    SET_PUSH_AUTH(state, value) {
        state.pushAuth = value
    },
    TOGGLE_VISIBILITY_STATE(state, value) {
        state.visibilityState = value
    },
    INCRIMENT_PWA_COUNTER(state, name) {
        state.pwaCounter[name]++
        setAppBadge(state.pwaCounter)
    },
    DICRIMENT_PWA_COUNTER(state, name) {
        state.pwaCounter[name]--
        setAppBadge(state.pwaCounter)
    },
    SET_PWA_COUNTER(state, { name, value }) {
        state.pwaCounter[name] = +value
        setAppBadge(state.pwaCounter)
    },
    ADD_PWA_COUNTER(state, { name, value }) {
        state.pwaCounter[name] = state.pwaCounter[name] + +value
        setAppBadge(state.pwaCounter)
    },
    REMOVE_PWA_COUNTER(state, { name, value }) {
        state.pwaCounter[name] = state.pwaCounter[name] - +value
        if (state.pwaCounter === 0)
            clearAppBadge()
        else
            setAppBadge(state.pwaCounter)

    },
    CLEAR_PWA_COUNTER(state) {
        state.pwaCounter = 0
        clearAppBadge()
    },
    SET_PWA_POPUP(state, value) {
        state.deferredPrompt = value
    },
    PUSH_OPEN_DRAWERS(state, uid) {
        const index = state.openDrawers.findIndex(f => f.uid === uid)
        if(index !== -1) {
            state.openDrawers.splice(index, 1)
        }
        const zIndex = state.openDrawers?.[state.openDrawers.length - 1]?.zIndex + 200 || 1000
        state.openDrawers.push({ uid, zIndex: zIndex })
    },
    REMOVE_OPEN_DRAWERS(state, uid) {
        const index = state.openDrawers.findIndex(f => f.uid === uid)
        if(index !== -1) {
            state.openDrawers.splice(index, 1)
        }
    },

}